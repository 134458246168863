import {
  ExclamationCircleIcon,
  XMarkIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import React from "react";

type AlertProps = {
  message: string;
  type: "success" | "error" | "info" | "warning";
  onDismiss?: () => void;
};

const alertStyles = {
  success: {
    container: "bg-green-50",
    iconColor: "text-green-400",
    textColor: "text-green-800",
    buttonBg: "bg-green-50",
    buttonHoverBg: "bg-green-100",
    ringColor: "ring-green-600",
    ringOffsetColor: "ring-green-50",
    icon: CheckCircleIcon,
  },
  error: {
    container: "bg-red-50",
    iconColor: "text-red-400",
    textColor: "text-red-800",
    buttonBg: "bg-red-50",
    buttonHoverBg: "bg-red-100",
    ringColor: "ring-red-600",
    ringOffsetColor: "ring-red-50",
    icon: ExclamationCircleIcon,
  },
  info: {
    container: "bg-blue-50",
    iconColor: "text-blue-400",
    textColor: "text-blue-800",
    buttonBg: "bg-blue-50",
    buttonHoverBg: "bg-blue-100",
    ringColor: "ring-blue-600",
    ringOffsetColor: "ring-blue-50",
    icon: InformationCircleIcon,
  },
  warning: {
    container: "bg-yellow-50",
    iconColor: "text-yellow-400",
    textColor: "text-yellow-800",
    buttonBg: "bg-yellow-50",
    buttonHoverBg: "bg-yellow-100",
    ringColor: "ring-yellow-600",
    ringOffsetColor: "ring-yellow-50",
    icon: ExclamationTriangleIcon,
  },
};

export const Alert: React.FC<AlertProps> = ({ message, type, onDismiss }) => {
  const styles = alertStyles[type];
  const Icon = styles.icon;

  return (
    <div className={`rounded-md p-4 ${styles.container}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 ${styles.iconColor}`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className={`text-sm font-medium ${styles.textColor}`}>{message}</p>
        </div>
        {onDismiss && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={`inline-flex rounded-md p-1.5 ${styles.buttonBg} ${styles.textColor} hover:${styles.buttonHoverBg} focus:outline-none focus:ring-2 focus:${styles.ringColor} focus:ring-offset-2 focus:${styles.ringOffsetColor}`}
                onClick={onDismiss}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
