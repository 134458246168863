import React from "react";
import useAlerts from "../../hooks/useAlerts";
import { Alert } from "./Alert";

const AlertComponent: React.FC = () => {
  const { alerts, removeAlert } = useAlerts();

  return (
    <div className="alert-container">
      {alerts.map((alert) => (
        <Alert
          key={alert.id}
          message={alert.message}
          type={alert.type}
          onDismiss={() => removeAlert(alert.id)}
        />
      ))}
    </div>
  );
};

export default AlertComponent;
